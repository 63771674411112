type Image = {
    [key: string]: string;
} | object;

type Config = Image;

const defaultConfig = {
    large: '600w',
    medium: '407w',
    small: '327w'
};


type DefinedConfig = {
    public: {
        imageSourceSets: Record<string, Record<string, string>>;
    };
};
const useSrcSets = () => {
    const {
        public: {imageSourceSets}
    } = useRuntimeConfig() as unknown as DefinedConfig;

    const applySrcsetConfig = (
        image: Image,
        config: Config = imageSourceSets?.default ?? defaultConfig
    ) =>
        Object.keys(config)
            .map((key) =>
                image?.[key as keyof typeof image] ? // Check if image has that srcset key
                    `${image[key as keyof typeof image]} ${config[key as keyof typeof image]}` :
                    null
            )
            .filter(Boolean)
            .join(', ');

    const getSrcSet =  (
        image: Image,
        configKey: string = 'default'
    ) => {
        let config = imageSourceSets?.[configKey as keyof typeof imageSourceSets];
        if(!config) {
            config = defaultConfig;
            if(configKey !== 'default') {
                console.warn(`No img srcset config found for '${configKey}'`);
            }
        }
        return applySrcsetConfig(
            image,
            config
        );
    };

    return { getSrcSet };
};

export default useSrcSets;
